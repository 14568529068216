<template>
  <v-card width="320" color="primary">
    <v-card-title class="white--text">产品中心</v-card-title>
    <v-card-subtitle class="white--text">Products</v-card-subtitle>
    <v-list>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
        >
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    selectedItem: 1,
    items: [
      { text: 'Generic', icon: 'mdi-clock', to: '/products/1' },
      { text: 'Enhanced', icon: 'mdi-account', to: '/products/2' },
      { text: 'Toughened', icon: 'mdi-flag', to: '/products/3' },
      { text: 'Flame Retardant', icon: 'mdi-flag', to: '/products/4' },
      { text: 'Enhanced and Toughened', icon: 'mdi-flag', to: '/products/5' },
    ],
  }),
}
</script>
