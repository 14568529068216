<template>
  <v-container fluid class="pa-0">
    <v-row>
      <img src="../assets/products.png" height="100%" width="100%">
    </v-row>
    <v-row>
      <v-col
        cols="3"
        class="d-none d-md-block"
      >
        <ProductsMenu class="mx-4 my-4"></ProductsMenu>
        <ContactUs class="mx-4 mb-4"></ContactUs>
      </v-col>
      <v-col
        cols="12"
        md="9"
        class="px-6"
      >
        <v-row align="center" class="title blue--text text--darken-2 pa-2">
          <v-icon class="blue--text text--darken-2">mdi-arrow-down-bold-hexagon-outline</v-icon>
          Shenmamid&reg; {{ currentProduct.name }}
          <v-spacer></v-spacer>
          <v-breadcrumbs
            :items="items"
            class="d-none d-lg-block py-0"
          ></v-breadcrumbs>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-img
              contain
              max-width="480"
              :aspect-ratio="4/3"
              :src="require(`@/assets/img/${currentProduct.img}`)"
              class="grey lighten-4">
            </v-img>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <h2>Shenmamid&reg; {{ currentProduct.name }}</h2>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <h1 class="blue--text text--darken-2">Detail</h1>
        <v-divider></v-divider>
        <v-img
          contain
          :src="require(`@/assets/img/detail/${currentProduct.name}.png`)"
        >
        </v-img>
        <v-divider></v-divider>
        <v-row justify="center">
          <v-btn
            color="blue-grey lighten-3"
            class="ma-2 white--text"
            @click="goBack"
          >
            Go Back
            <v-icon right dark>mdi-undo</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductsMenu from './ProductsMenu'
import ContactUs from './ContactUs'

import productlist from '@/assets/json/productlist.json'

export default {
  components: {
    ProductsMenu,
    ContactUs
  },

  data: () => ({
    currentProduct: {},
    products: productlist.products,
    items: [
    {
      text: 'Home',
      disabled: false,
      href: '/',
    },
    {
      text: 'Products',
      disabled: false,
      href: '/products/0',
    },
    {
      text: 'ProductsType',
      disabled: false,
      href: '/products/1',
    },
    {
      text: 'ProductDetail',
      disabled: true,
      href: '/productdetail/1',
    },
  ],
  }),

  created () {
    this.fetchData()
  },

  watch: {
    '$route': 'fetchData'
  },

  methods: {
    fetchData () {
      var id = this.$route.params.id
      this.currentProduct = this.products.filter(function(item){
        return item.id == id
      })[0]

      this.items[3].text = "Shenmamid® " + this.currentProduct.name
      this.items[3].href = "/productdetail/" + this.currentProduct.id
      switch (this.currentProduct.type) {
        case "1":
          this.items[2].text = "Generic"
          break
        case "2":
          this.items[2].text = "Enhanced"
          break
        case "3":
          this.items[2].text = "Toughened"
          break
        case "4":
          this.items[2].text = "Flame Retardant"
          break
        case "5":
          this.items[2].text = "Enhanced and Toughened"
          break
        default:
          this.items[2].text = "All"
      }
      this.items[2].href = "/products/" + this.currentProduct.type
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>
